import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React from 'react'
import TitleComp from '../../component/TitleComp'

const ContestUserdetail = () => {
    const getRowId = (row) => row._id
    const columns = [


        {
            field: "name",
            headerName: "Name",
            flex: 1,

        },


        {
            field: "email",
            headerName: "Email",
            flex: 1,

        },
        {
            field: "phoneno",
            headerName: "Phone",
            flex: 1,

        },
        {
            field: "action",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className="d-flex gap-3 mt-1">
                            {/* <button className='btn bg-yellow text-white' ><i class="fa-solid fa-pen-to-square"></i></button> */}
                            <button className='btn bg-yellow text-white' ><i class="fa-solid fa-trash"></i></button>
                        </div>
                    </>
                )
            }

        },


    ]
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <TitleComp title="Contest Users List" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-body-tertiary mt-2" style={{ overflow: "hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <Box>
                                    <DataGrid
                                        // rows={rows}
                                        columns={columns}
                                        getRowId={getRowId}
                                    />

                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContestUserdetail
