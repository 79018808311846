import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ModalComponent = ({ content, title, onCancel }) => {
    return (
        <>
            {/* Bootstrap Modal */}
            <div className="modal show d-block" tabIndex="-1" role="dialog" aria-labelledby="modal-title" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content rounded-3 shadow-lg">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal-title">{title}</h5>
                            <button type="button" className="btn-close" onClick={onCancel} aria-label="Close"></button>
                        </div>

                        {/* Modal Body */}
                        <div className="modal-body">
                            {content}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Backdrop */}
            <div className="modal-backdrop show"></div>
        </>
    );
};

export default ModalComponent;
