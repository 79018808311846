import React, { useEffect, useState } from 'react'
import TitleComp from '../../component/TitleComp'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import { BASE_URL } from '../../URL'
import { getwithheader, putwithheader } from '../../Api/Apis'
import { toast } from 'react-toastify'
import Loader from '../../component/Loader'

const Createquestion = () => {
    let token = localStorage.getItem("paneltoken")
    const [loading, setloading] = useState(true)
    const [data, setdata] = useState([]);
    const [categorydata, setcategorydata] = useState([]);
    const [contestdata, setcontestdata] = useState([]);
    const [QuestionType, setQuestionType] = useState('');
    const [contestfilter, setcontestfilter] = useState('');
    const [contestname, setcontestname] = useState("")
    const [contest, setcontest] = useState("");
    const [category, setcategory] = useState("");
    const [question, setquestion] = useState("");
    const [option1, setoption1] = useState("");
    const [option2, setoption2] = useState("");
    const [option3, setoption3] = useState("");
    const [option4, setoption4] = useState("");
    const [correctOption, setcorrectOption] = useState("");
    const [editid, seteditid] = useState("")

    const handleQuestionTypeChange = (event) => {
        setQuestionType(event.target.value);
    };
    const headers = {
        Authorization: `Bearer ${token}`

    }
    const handlecategory = async () => {
        let res = await getwithheader(`category`, token)
        setcategorydata(res.data)

    }
    const handlecontest = async () => {
        let res = await getwithheader(`allcontest`, token)
        setcontestdata(res.data)
    }
    const handlegetquestions = async () => {
        setloading(true); // Show loader before fetching
        try {
            const res = await axios.get(`${BASE_URL}questions`, { headers });
            setdata(res.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setloading(false); // Hide loader after fetching
        }
    };
    // const handlegetquestions = async () => {
    //     let res = await getwithheader(`questions`, token)
    //     setdata(res.data)
    // }
    useEffect(() => {
        handlecategory()
        handlecontest()
        handlegetquestions()

    }, [])
    const handleStatusChange = async (e, id, status) => {

        e.preventDefault()
        let requestdata = {
            isActive: !status
        }
        const res = await putwithheader(`questions/${id}`, requestdata, token)
        if (res.error == "0") {
            toast.success(res.message)
            // handlegetContest();
            // handleclear();
            seteditid("")
            handlegetquestions()


        } else {
            toast.error("Data not Submit")

        }
    }
    const handlesubmit = async (e) => {
        e.preventDefault();
        setloading(true)
        let requestdata = {
            contest: contest,
            category: category,
            question: question,
            questionType: QuestionType,

            options: QuestionType == "MCQ" ? [`${option1}`, `${option2}`, ` ${option3}`, `${option4}`] : ['True', 'False'],
            correctOption: correctOption,
        }
        if (!editid) {
            axios.post(`${BASE_URL}questions`, requestdata, { headers }).then((response) => {
                // console.log(response);
                if (response.data.error == "0") {
                    toast.success("data submitted successfully");
                    setloading(false);
                    handlegetquestions();
                    handleclear();
                } else if (response.data.error == "1") {
                    toast.error(response.data.message || "An error occurred");
                }
            })
        } else {
            const res = await putwithheader(`questions/${editid}`, requestdata, token)
            if (res.error == "0") {
                toast.success(res.message)
                setloading(false);
                handlegetquestions()
                handleclear();
                seteditid("")

            } else {
                toast.error("Data not Submit")
                setloading(false);

            }
        }

    }
    const handleclear = () => {
        setcontest("");
        setcategory("");
        seteditid("")
        setquestion("")
        setoption1("")
        setoption2("")
        setoption3("")
        setoption4("")
    }
    const handleedit = (e, item) => {
        e.preventDefault()
        setcontest(item.contest?._id || "");
        setcategory(item.category?._id || "");
        seteditid(item._id)
        setquestion(item.question)
        setoption1(item.options[0])
        setoption2(item.options[1])
        setoption3(item.options[2])
        setoption4(item.options[3])



        setcorrectOption(item.correctOption)
        setQuestionType(item.questionType)
    }
    const handledelte = (e, id) => {
        e.preventDefault()
        const isConfirmed = window.confirm("Are you sure you want to delete this category?");
        if (!isConfirmed) {
            return;
        }
        axios.delete(`${BASE_URL}delete-questions/${id}`, { headers }).then((res) => {
            if (res.data.error == "0") {
                toast.success(res.data.message)
                handlegetquestions()
            }
        })
    }
    const getRowId = (row) => row._id

    const columns = [


        {
            field: "contest",
            headerName: "Contest Name",
            flex: 1,
            renderCell: (params) => (
                <span>{params.row.contest?.name ?? contestname}</span>
            ),
        },
        {
            field: "isActive",
            headerName: "IsActive",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div class="checkbox-con" onClick={(e) => handleStatusChange(e, params.row._id, params.row.isActive)}>
                            <input id="checkbox" checked={params.row.isActive} type="checkbox" />
                        </div>
                    </>
                )
            }

        },
        {
            field: "category",
            headerName: "Category Name",
            flex: 1,
            renderCell: (params) => (
                <span>{params.row.category?.name || "N/A"}</span>
            ),
        },


        {
            field: "question",
            headerName: "Question",
            flex: 1,

        },
        {
            field: "option1",
            headerName: "Options",
            flex: 1,
            renderCell: (params) => (
                <span>{params.row.options?.[0] || "N/A"}</span>
            ),
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className="d-flex gap-3 mt-1">
                            <button className='btn bg-yellow text-white' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
                            <button className='btn bg-yellow text-white' onClick={(e) => handledelte(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                        </div>
                    </>
                )
            }

        },

    ]


    const handleQuestionbycontest = async () => {
        let res = await getwithheader(`questionsbycontest/${contestfilter}`)
        setdata(res.questions)
        setcontestname(res?.contest)


    }

    useEffect(() => {
        if (contestfilter != "all" && contestfilter != "") {
            handleQuestionbycontest()
        } else {
            handlegetquestions()
        }
    }, [contestfilter])



    console.log(contestfilter)
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <TitleComp title="Create Question" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <form onSubmit={handlesubmit}>
                                <div className="row">
                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="contestSelect">Select Contest</label>
                                        <select
                                            id="contestSelect"
                                            value={contest}
                                            onChange={(e) => setcontest(e.target.value)}
                                            className="form-select">
                                            <option value="">Select a contest</option>
                                            {contestdata.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item?._id}>{item.name}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="contestSelect">Select Category</label>
                                        <select
                                            value={category}
                                            onChange={(e) => setcategory(e.target.value)}
                                            className="form-control 
                                        form-select" >
                                            <option>Select Category</option>
                                            {categorydata.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item?._id}>{item.name}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="">Question</label>
                                        <input
                                            type='text'
                                            value={question}
                                            onChange={(e) => setquestion(e.target.value)}
                                            className='form-control' />
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="questionType">Question Type</label>
                                        <select
                                            id="questionType"
                                            className="form-select"
                                            value={QuestionType}
                                            onChange={handleQuestionTypeChange}
                                        >
                                            <option value="">Select question type</option>
                                            <option value="true_false">True & False</option>
                                            <option value="MCQ">Multiple Option</option>
                                        </select>
                                    </div>

                                    {QuestionType === 'MCQ' && (
                                        <>
                                            <div className="col-md-4 mt-2">
                                                <label htmlFor="option1">Option 1</label>
                                                <input
                                                    type="text"
                                                    value={option1}
                                                    onChange={(e) => setoption1(e.target.value)}
                                                    className="form-control"
                                                    id="option1" />
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <label htmlFor="option2">Option 2</label>
                                                <input
                                                    type="text"
                                                    value={option2}
                                                    onChange={(e) => setoption2(e.target.value)}
                                                    className="form-control"
                                                    id="option2" />
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <label htmlFor="option3">Option 3</label>
                                                <input
                                                    type="text"
                                                    value={option3}
                                                    onChange={(e) => setoption3(e.target.value)}
                                                    className="form-control"
                                                    id="option3" />
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <label htmlFor="option4">Option 4</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={option4}
                                                    onChange={(e) => setoption4(e.target.value)}
                                                    id="option4" />
                                            </div>

                                        </>
                                    )}

                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="correctOption">Correct Option</label>
                                        <select
                                            id="correctOption"
                                            value={correctOption}
                                            onChange={(e) => setcorrectOption(e.target.value)}
                                            className="form-select">
                                            <option value="">Select Correct Option</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            {QuestionType == "MCQ" &&
                                                <>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </>}
                                        </select>
                                    </div>


                                    <div className="col-md-12 mt-2">
                                        <button className='btn bg-yellow text-white' type='submit'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-body-tertiary mt-2 " style={{ overflow: "hidden" }}>
                <div className="container">
                    <div className="row my-4">

                        <div className="col-md-6">
                            <div className='mb-2'>
                                <label htmlFor="">Filter Question </label>
                                <select
                                    id="contestSelect"
                                    value={contestfilter}
                                    onChange={(e) => setcontestfilter(e.target.value)}
                                    className="form-select">
                                    <option value="all">All Contest</option>
                                    {contestdata.map((item) => {
                                        return (
                                            <>
                                                <option value={item?._id}>{item.name}</option>
                                            </>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="">
                                <Box>
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                    />

                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Createquestion
