import { useEffect, useState } from "react"





import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { getwithheader } from "../../Api/Apis"
import Loader from "../../component/Loader"
import TitleComp from "../../component/TitleComp"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"





function Defaultpermission() {

    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [name, setname] = useState("")

    const handleget = async () => {
        setloading(true)
        let res = await getwithheader(`default-permission`)
        if (res.error == 0) {
            setloading(false)
            setdata(res?.data)
        } else {
            setloading(false)
        }

    }
    useEffect(() => {
        handleget()
    }, [])



    const navigate = useNavigate()

    const handlenavigate = () => {
        navigate('/addpermission')
    }
    const columns = [


        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },


        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex gap-3 mt-1">
                        <button className="btn bg-yellow text-white" >
                            {/* onClick={(e) => handleedit(e, params.row)} */}
                            <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button className="btn bg-yellow text-white" >
                            {/* onClick={(e) => handledelete(e, params.row._id)} */}
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                );
            },
        },

    ]


    const getRowId = (row) => row._id
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="">
                                <TitleComp title="Default Permission" />
                            </div>
                        </div>
                        <div className="col-md-6 text-end">
                            <button onClick={() => navigate('/add-permission')} className="btn bgblue flex gap-2 items-center text-white py-2 px-4 text-[18px] font-[700] rounded-lg">Add Default Permission</button>
                        </div>
                        <div className="col-md-12 px-3">
                            <Box>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                />

                            </Box>
                        </div>
                    </div>
                </div>
            </section>
            {/* <PageHeader title="Default Permission" btn="Add Default Permission" onClick={() => handlenavigate()} /> */}

        </>
    )
}

export default Defaultpermission

