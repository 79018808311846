import React, { useEffect, useRef, useState } from 'react'
import TitleComp from '../../component/TitleComp'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';
import { BASE_URL } from '../../URL';
import { toast } from 'react-toastify';
import { getwithheader, putwithheader } from '../../Api/Apis';
import moment from 'moment/moment';
import Loader from '../../component/Loader';
import { useNavigate, useParams } from 'react-router-dom';


const Createcontest = () => {
    let token = localStorage.getItem("paneltoken")
    const navigate = useNavigate();

    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])
    const [file, setfile] = useState("");
    const [name, setname] = useState("");
    const [about, setabout] = useState("");
    const [termsConditions, settermsConditions] = useState("");
    const [questionsPerCategory, setquestionsPerCategory] = useState("");
    const [duration, setduration] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");
    const [point, setpoint] = useState("")
    const [editid, seteditid] = useState("");
    const [editimage, seteditimage] = useState("");
    const [preview, setPreview] = useState(null);
    const [postimage, setPostImage] = useState("");

    const fileInputRef = useRef(null);


    const headers = {
        Authorization: `Bearer ${token}`

    }
    const handlefile = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setfile(selectedFile);
            setPreview(URL.createObjectURL(selectedFile)); // Create preview URL
        }
    };
    const handleabout = (event, editor) => {
        const data = editor.getData();
        setabout(data);
    };
    const handletermsConditions = (event, editor) => {
        const data = editor.getData();
        settermsConditions(data);
    };
    const handlegetContest = async () => {
        setloading(true); // Show loader before fetching
        try {
            const res = await axios.get(`${BASE_URL}allcontest`, { headers });
            setdata(res.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setloading(false); // Hide loader after fetching
        }
    };
    // const handlegetContest = async () => {
    //     let res = await getwithheader(`allcontest`, token)
    //     setdata(res.data)
    // }

    useEffect(() => {
        handlegetContest()
    }, [])

    const handleStatusChange = async (e, id, status) => {

        e.preventDefault()
        let requestdata = {
            status: !status
        }
        const res = await putwithheader(`contest/${id}`, requestdata, token)
        if (res.error == "0") {
            toast.success(res.message)
            // handlegetContest();
            // handleclear();
            seteditid("")
            handlegetContest()


        } else {
            toast.error("Data not Submit")

        }
    }
    const handlesubmit = async (e) => {
        e.preventDefault();
        setloading(true)
        let formdata = new FormData();
        if (file) {
            formdata.append("image", file);
        }
        formdata.append("name", name);
        formdata.append("about", about);
        formdata.append("termsConditions", termsConditions);
        formdata.append("questionsPerCategory", questionsPerCategory);
        formdata.append("duration", duration);
        formdata.append("startDate", startDate);
        formdata.append("endDate", endDate);
        formdata.append("points", point);
        if (!editid) {
            axios.post(`${BASE_URL}contest`, formdata, { headers }).then((response) => {
                // console.log(response);
                if (response.data.error == "0") {
                    toast.success("data submitted successfully");
                    setloading(false)
                    setPostImage(""); // Assuming API returns uploaded image path
                    handlegetContest();
                    handleclear();
                    setfile(null);
                    setPreview(null);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = ""; // Reset file input
                    }
                }
            })
        } else {
            const res = await putwithheader(`contest/${editid}`, formdata, token)
            if (res.error == "0") {
                toast.success(res.message)
                setloading(false)
                handlegetContest();
                handleclear();
                seteditid("")

            } else {
                toast.error("Data not Submit")
                setloading(false)

            }
        }

    }
    const handleclear = () => {
        setfile("")
        setname("")
        setabout("")
        seteditid("")
        settermsConditions("")
        setquestionsPerCategory("")
        setduration("")
        setstartDate("")
        setendDate("")
        setPostImage("")
        setpoint("")

    }
    const handleedit = (e, item) => {
        e.preventDefault()
        // setfile(item.image)
        seteditimage(item.image)
        setname(item.name)
        setabout(item.about)
        seteditid(item._id)
        settermsConditions(item.termsConditions)
        setquestionsPerCategory(item.questionsPerCategory)
        setduration(item.duration)
        setstartDate(item.startDate.substring(0, 10))
        setendDate(item.endDate.substring(0, 10))
        setpoint(item.point)
    }

    const handledelte = (e, id) => {

        e.preventDefault()
        const isConfirmed = window.confirm("Are you sure you want to delete this category?");
        if (!isConfirmed) {
            return;
        }
        axios.delete(`${BASE_URL}delete-contest/${id}`, { headers }).then((res) => {
            if (res.data.error == "0") {
                toast.success(res.data.message)
                handlegetContest()
            }
        })
    }

    const getRowId = (row) => row._id

    const columns = [

        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {
                return (
                    <img src={`${BASE_URL}${params.row.image}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} alt="" />
                )
            }
        },

        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },


        {
            field: "status",
            headerName: "Status",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div class="checkbox-con" onClick={(e) => handleStatusChange(e, params.row._id, params.row.status)}>
                            <input id="checkbox" checked={params.row.status} type="checkbox" />
                        </div>
                    </>
                )
            }

        },

        // {
        //     field: "topUsers",
        //     headerName: "Top Users",
        //     flex: 1,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <span className='pt-2'>{params.row?.topUsers[0]?.name}</span>
        //             </>
        //         )
        //     }

        // },
        {
            field: "participants",
            headerName: "Participants",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <span className='pt-2'>{params.row?.participants?.length}</span>
                    </>
                )
            }
        },
        {
            field: "questionsPerCategory",
            headerName: "Questions",
            flex: 1,

        },
        {
            field: "points",
            headerName: "Point",
            flex: 1,

        },

        {
            field: "startDate",
            headerName: "Start Date",
            flex: 1,
            renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
        },
        {
            field: "endDate",
            headerName: "End Date",
            flex: 1,
            renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),

        },


        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className="d-flex gap-3 mt-1">
                            <button className='btn bg-yellow text-white' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>

                        </div>
                    </>
                )
            }

        },
        {
            field: "details",
            headerName: "Detail",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className="d-flex gap-3 mt-1">
                            <button className='btn bg-yellow text-white' onClick={() => navigate(`contest-result/${params.row._id}`)}>  <i class="fa-solid fa-eye"></i></button>
                        </div>
                    </>
                )
            }

        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className="d-flex gap-3 mt-1">
                            <button className='btn bg-yellow text-white' onClick={(e) => handledelte(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                        </div>
                    </>
                )
            }

        },

    ]
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <TitleComp title="Create Contest" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <form onSubmit={handlesubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        {preview ? (
                                            <img src={preview} alt="Preview" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
                                        ) : editid && editimage ? (
                                            <img src={`${BASE_URL}${editimage}`} alt="Existing Image" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
                                        ) : (
                                            !editid && postimage && (
                                                <img src={`${BASE_URL}${postimage}`} alt="Uploaded Image" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
                                            )
                                        )}
                                    </div>


                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="">Image</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={handlefile}

                                        />
                                    </div>
                                    <div className="col-md-4 mt-2">

                                        <label htmlFor="">Create Contest</label>
                                        <input
                                            type='text'
                                            value={name}
                                            onChange={(e) => setname(e.target.value)}
                                            className='form-control' />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="questionLimit">Question Limit</label>
                                        <input
                                            type="number"
                                            id="questionLimit"
                                            value={questionsPerCategory}
                                            onChange={(e) => setquestionsPerCategory(e.target.value)}
                                            className="form-control" min="1" />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="durationInSeconds">Duration (in seconds)</label>
                                        <input
                                            type="number"
                                            id="durationInSeconds"
                                            value={duration}
                                            onChange={(e) => setduration(e.target.value)}
                                            className="form-control" min="1" />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="durationInSeconds">Point</label>
                                        <input
                                            type="number"
                                            id="durationInSeconds"
                                            value={point}
                                            onChange={(e) => setpoint(e.target.value)}
                                            className="form-control" min="1" />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="startDate">Start Date</label>
                                        <input
                                            type="date"
                                            id="startDate"
                                            value={startDate}
                                            onChange={(e) => setstartDate(e.target.value)}
                                            className="form-control" />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label htmlFor="endDate">End Date</label>
                                        <input
                                            type="date"
                                            id="endDate"
                                            value={endDate}
                                            onChange={(e) => setendDate(e.target.value)}
                                            className="form-control" />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">About</label>
                                        <CKEditor
                                            data={about}
                                            onChange={handleabout}
                                            editor={ClassicEditor}
                                            className="custom-ckeditor"
                                            id="about"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="termscondition">Terms and Condition</label>
                                        <CKEditor
                                            data={termsConditions}
                                            onChange={handletermsConditions}
                                            editor={ClassicEditor}
                                            className="custom-ckeditor"
                                            id="termscondition"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <button className='btn bg-yellow text-white' type='submit'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-body-tertiary mt-2" style={{ overflow: "hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <Box>
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                    />

                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Createcontest
