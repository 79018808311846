import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import TitleComp from '../../component/TitleComp'
import { BASE_URL } from '../../URL'
import { deleteapi, getwithheader } from '../../Api/Apis'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Createcommunity from './Createcommunity'

const Communitylist = () => {
    const navigate = useNavigate();
    let token = localStorage.getItem("paneltoken")
    const [data, setdata] = useState([])
    const handleget = async () => {
        const res = await getwithheader('fetchPost', token)
        console.log(res.data)
        setdata(res.data)
    }
    useEffect(() => {
        handleget()
    }, [])
    const handledelete = async (e, id) => {
        e.preventDefault()
        const res = await deleteapi(`posts/${id}`, token)
        if (res.error == "0") {
            toast.success(res.message)
            handleget()
        } else {
            toast.error("Data not Submitted")
        }
    }

    const handleedit = (e, item) => {
        e.preventDefault();
        navigate('/community', { state: { ...item, id: item._id } });
    };
    const columns = [
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <img src={`${BASE_URL}${params.row.image}`} style={{ objectFit: "cover", height: "50px", width: "50px", borderRadius: "50%" }} alt="" />
                    </>
                )
            }
        },
        {
            field: "icon",
            headerName: "Icon",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <img src={`${BASE_URL}${params.row.icon}`} style={{ objectFit: "cover", height: "50px", width: "50px", borderRadius: "50%" }} alt="" />
                    </>
                )
            }
        },

        {
            field: "title",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "subtitle",
            headerName: "Sub Title",
            flex: 1,
        },
        {
            field: "post_message",
            headerName: "Description",
            flex: 1,
        },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex gap-3 mt-1">
                        <button className="btn bg-yellow text-white" onClick={(e) => handleedit(e, params.row)} >
                            <i className="fa-solid fa-pen-to-square"></i>
                        </button>

                    </div>
                );
            },
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex gap-3 mt-1">

                        <button className="btn bg-yellow text-white" onClick={(e) => handledelete(e, params.row._id)}>
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ]
    const getRowId = (row) => row._id
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <TitleComp title="Happenings List" />
                    </div>
                </div>
                <div className="col-md-12 px-4">
                    <Box>
                        <DataGrid rows={data} columns={columns} getRowId={getRowId} />
                    </Box>

                    {/* <Table data={data} columns={columns} getRowId={getRowId} /> */}
                </div>
            </div>
        </>
    )
}

export default Communitylist
