import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { postwithheader, putwithheader } from '../../Api/Apis'
import { toast } from 'react-toastify'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const Createcommunity = () => {
    const { state } = useLocation();
    const id = state?.id;
    let token = localStorage.getItem("paneltoken")
    // let userId = localStorage.getItem("userid")
    const [loading, setloading] = useState(false)
    const [title, settitle] = useState();
    const [subtitle, setsubtitle] = useState();
    const [file, setfile] = useState();
    const [icon, seticon] = useState();
    const [description, setdescription] = useState();
    const handlefile = (e) => {
        let selectedfile = e.target.files[0]
        setfile(selectedfile)
    }
    const handlefileicon = (e) => {
        let selectedfile = e.target.files[0]
        seticon(selectedfile)
    }
    const navigate = useNavigate()
    const handlesubmit = async (e) => {
        e.preventDefault();
        let formdata = new FormData()
        formdata.append("title", title)
        formdata.append("subtitle", subtitle)
        formdata.append("image", file)
        formdata.append("icon", icon)
        formdata.append("post_message", description)
        // formdata.append("userId", userId)
        if (state) {
            const res = await putwithheader(`posts/${id}`, formdata, token)
            console.log(res)
            if (res.error == "0") {
                toast.success(res.message)
                setloading(false)
            } else {
                toast.error(res.message)
                setloading(false)
            }
        } else {
            const res = await postwithheader('addNewPost', formdata, token)
            if (res.error == "0") {
                toast.success(res.message)

                // handleget()
                navigate('/community-list')
            } else {
                toast.error("Data not Submit")
            }
        }

    }
    useEffect(() => {
        if (state) {
            settitle(state.title);
            setsubtitle(state.subtitle);
            setfile(state.image);
            seticon(state.icon);
            setdescription(state.post_message);
        }
    }, [state]);
    return (
        <>
            <section className='bg-body-tertiary'>
                <div className="container-fluid">
                    <h3 className="heading">Happenings</h3>
                    <Form onSubmit={handlesubmit}>
                        <div className="row  align-items-center mb-3">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Image</label>
                                    <input type="file" placeholder="" className="form-control" onChange={handlefile} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Icon</label>
                                    <input type="file" placeholder="" className="form-control" onChange={handlefileicon} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Title</label>
                                    <input type="text" placeholder="Title" className="form-control" required value={title} onChange={(e) => settitle(e.target.value)} />
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Sub Title</label>
                                    <input type="text" placeholder="Subtitle" className="form-control" required value={subtitle} onChange={(e) => setsubtitle(e.target.value)} />
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Description</label>
                                    <input type="text" placeholder="Description" className="form-control" required value={description} onChange={(e) => setdescription(e.target.value)} />
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="my-3">
                                    <Button className="btn bg-yellow" type="submit" >Submit</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </section>
        </>
    )
}

export default Createcommunity
