import React from 'react'
import { Bars } from 'react-loader-spinner'

function Loader() {
    return (
        <>
            <div className="overlay2">
                <Bars
                    height="80"
                    width="80"
                    color="#ed3237"
                    ariaLabel="bars-loading"
                    visible={true}
                />
            </div>
        </>
    )
}

export default Loader
