import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { deleteapi, getwithheader, postwithheader, putwithheader } from '../../Api/Apis'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { BASE_URL } from '../../URL'
import axios from 'axios'

function Banner() {

    let token = localStorage.getItem("paneltoken")
    const [file, setfile] = useState([])
    const [position, setposition] = useState("")
    const [type, settype] = useState("")
    const [title, settitle] = useState("")
    const [data, setdata] = useState([])
    const [previewUrl, setPreviewUrl] = useState(null);
    const [editid, seteditid] = useState("")



    const handlefile = (e) => {
        const selectedFiles = Array.from(e.target.files);
        // Update the state with the selected files
        setfile(selectedFiles);

        // You can also log each selected file if needed
        selectedFiles.forEach(file => {
            console.log('Selected file:', file);
        });
    };

    const handleget = async () => {
        const res = await getwithheader('banner', token)
        console.log(res.data)
        setdata(res.data)


    }

    useEffect(() => {
        handleget()
    }, [])

    const handledelete = async (e, item) => {
        e.preventDefault()
        const res = await deleteapi(`banner/${item._id}`, token)
        if (res.error == "0") {
            toast.success(res.message)
            handleget()
        } else {
            toast.error("Data not Submitted")
        }
    }

    const handleformsubmit = async (e) => {
        e.preventDefault();
        let formdata = new FormData();

        // Add common fields
        formdata.append("position", position);
        formdata.append("type", type);
        formdata.append("title", title);

        // Conditionally append files if provided
        if (file && file.length > 0) {
            file.forEach((file, index) => {
                formdata.append("image", file);
            });
        }

        try {
            let res;
            if (!editid) {
                // Create a new banner
                res = await postwithheader('banner', formdata, token);
            } else {
                // Update an existing banner
                res = await putwithheader(`banner/${editid}`, formdata, token);
            }

            // Handle response
            if (res.error == "0") {
                toast.success(res.message);
                handleget();
                settype("")
                setposition("")
                seteditid("")
                settitle("")
            } else {
                toast.error("Data not Submit");
            }
        } catch (err) {
            console.error("Error during form submission:", err);
            toast.error("An error occurred. Please try again.");
        }
    };




    const getRowId = (row) => row._id

    const columns = [
        {
            field: "image",
            headerName: "image",
            flex: 1,
            renderCell: (params) => {
                return (
                    <img src={`${BASE_URL}${params.row.image[0]}`} className='img-fluid' style={{ height: "50px", width: "50px", borderRadius: "50%" }} alt="" />
                )
            }

        },
        {
            field: "position",
            headerName: "Position",
            flex: 1,

        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,

        },
        {
            field: "title",
            headerName: "Title",
            flex: 1,

        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className='d-flex gap-2 mt-1'>
                            <button className='accepted btn bg-yellow text-white' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i> </button>
                            <button className='accepted btn bg-yellow text-white' onClick={(e) => handledelete(e, params.row)} ><i class="fa-solid fa-trash"></i></button>
                        </div>
                    </>
                )
            }

        }
    ]

    const handleedit = (e, item) => {
        e.preventDefault()

        setposition(item.position)
        settype(item.type)
        settitle(item.title)
        seteditid(item._id)
    }




    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center mt-3">
                                <h3>Add Banner</h3>
                            </div>
                        </div>
                        <Form onSubmit={handleformsubmit}>
                            <div className="row">

                                <div className="col-md-4">

                                    <label htmlFor="">Image</label>

                                    <input type="file" multiple onChange={(e) => handlefile(e)} className='form-control' />
                                    {/* {(previewUrl || file) ? (
                                        previewUrl ? (
                                            <div className="mt-4">
                                                <img src={previewUrl} alt="Selected" style={{ height: '100px' }} />
                                            </div>
                                        ) : (
                                            <div className="mt-4">
                                                <img src={`${BASE_URL}${file}`} alt="Selected" style={{ height: '100px' }} />
                                            </div>
                                        )
                                    ) : null} */}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="">Position</label>
                                    <input type="text" className='form-control' value={position} onChange={(e) => setposition(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="">Type</label>
                                    <input type="text" className='form-control' value={type} onChange={(e) => settype(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="">Title</label>
                                    <input type="text" className='form-control' value={title} onChange={(e) => settitle(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <div className="mt-4">
                                        <button className='btn submitbtn'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </Form>


                    </div>
                </div>
            </section>
            <section className='mt-3'>
                <div className="container">
                    <div className="row">
                        <div className="text-center">
                            <h3>Banner List</h3>
                        </div>
                        <div className="col-md-12">
                            <Box>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                />

                            </Box>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner





