import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import TitleComp from '../../component/TitleComp'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../URL'
import Loader from '../../component/Loader'

const ContestLeaderboard = () => {
    let token = localStorage.getItem("paneltoken")
    const navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [data, setdata] = useState([]);
    const getRowId = (row) => row._id
    const headers = {
        Authorization: `Bearer ${token}`

    }
    const handleGet = async () => {
        setloading(true); // Show loader before fetching
        try {
            const res = await axios.get(`${BASE_URL}leaderboard`, { headers });
            setdata(res.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setloading(false); // Hide loader after fetching
        }
    };
    useEffect(() => {
        handleGet()
    }, []);


    const colors = ["primary", "success", "warning", "danger", "info"]; // Bootstrap Colors

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => <span>{params.row.user?.name || "N/A"}</span>,
        },
        {
            field: "contestAttended",
            headerName: "Contest Attended",
            flex: 1,
            renderCell: (params) => <span className='pt-2'>{params.row.attendContest ?? 0}</span>,
        },
        {
            field: "questionsAttended",
            headerName: "Questions Attended",
            flex: 1,
        },
        {
            field: "correctAnswers",
            headerName: "Correct Answers",
            flex: 1,
        },
        {
            field: "score",
            headerName: "Score",
            flex: 1,
        },
        {
            field: "timeTaken",
            headerName: "Complete in seconds",
            flex: 2,
        },
        {
            field: "rank",
            headerName: "Rank",
            flex: 1,
            renderCell: (params) => {
                const color = colors[params.row.rank % colors.length]; // Assign random color
                return (
                    <span className={`badge bg-${color} rounded-3 px-3 py-2`} style={{ fontSize: "14px" }}>
                        {params.row.rank}
                    </span>
                );
            },
        },
        {
            field: "updatedAt",
            headerName: "Last Updated",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "Date Created",
            flex: 1,
        },
    ];


    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <TitleComp title="Contest Leaderboard" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-body-tertiary mt-2" style={{ overflow: "hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <Box>
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                    />

                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContestLeaderboard
