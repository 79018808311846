import { useEffect, useState } from "react"
// import PageHeader from "../Component/PageHeader"

import { toast } from "react-toastify"
import { getwithheader, postapiwithoutheader, putwithheader } from "../../Api/Apis"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import ModalComponent from "../../component/ModalComponent"
import TitleComp from "../../component/TitleComp"
import Loader from "../../component/Loader"
import axios from "axios"
import { BASE_URL } from "../../URL"
function Usertype() {
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [name, setname] = useState("")
    const [show, setshow] = useState(false)
    const [editid, seteditid] = useState("");
    const handleget = async () => {
        setloading(true)
        let res = await getwithheader(`module`)
        if (res.error == 0) {
            setloading(false)
            setdata(res?.data)
        } else {
            setloading(false)
        }

    }
    useEffect(() => {
        handleget()
    }, [])


    const handlesubmit = async (e) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            name
        }
        if (editid) {
            let res = await putwithheader(`module/${editid}`, requestdata)
            if (res.error == 0) {
                setloading(false)
                toast.success(res.message)
                setshow(false)
                setname("")
                handleget()
                seteditid("")
            } else {
                setloading(false)
            }
        } else {
            let res = await postapiwithoutheader(`module`, requestdata)
            if (res.error == 0) {
                setloading(false)
                toast.success(res.message)
                setshow(false)
                setname("")
                handleget()

            } else {
                setloading(false)
            }
        }

    }
    const handleedit = (e, row) => {
        e.preventDefault();
        seteditid(row._id);
        setname(row.name);
        setshow(true);
    };
    const handledelte = (e, id) => {

        e.preventDefault()
        const isConfirmed = window.confirm("Are you sure you want to delete this category?");
        if (!isConfirmed) {
            return;
        }
        axios.delete(`${BASE_URL}delete-module/${id}`).then((res) => {
            if (res.data.error == "0") {
                toast.success(res.data.message)
                handleget()
            }
        })
    }
    const content = (
        <div>
            <form onSubmit={(e) => handlesubmit(e)}  >
                <div className="mb-3">
                    <label htmlFor="message" className="text-[16px] font-[500]">Name</label>
                    <input type="text" value={name} onChange={(e) => setname(e.target.value)} className=" form-control"
                        placeholder="Enter your User Name..." />
                </div>
                <button type='submit' className="btn bg-yellow text-white">Submit</button>
            </form>
        </div>
    );

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex gap-3 mt-1">
                        <button className="btn bg-yellow text-white" onClick={(e) => handleedit(e, params.row)}>
                            <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button className="btn bg-yellow text-white" onClick={(e) => handledelte(e, params.row._id)} >

                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ]
    const getRowId = (row) => row._id
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="">
                                <TitleComp title="User Type" />
                            </div>
                        </div>
                        <div className="col-md-6 text-end">
                            <button onClick={() => setshow(true)} className="btn bgblue flex gap-2 items-center text-white py-2 px-4 text-[18px] font-[700] rounded-lg">Add User</button>
                        </div>
                        <div className="col-md-12 px-3">
                            <Box>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                />

                            </Box>
                        </div>
                    </div>
                </div>
            </section>


            {show && <ModalComponent onCancel={() => setshow(false)} content={content} title={"Add User"} />}
        </>
    )
}

export default Usertype
